<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader
            v-if="api.isLoading"
            ref="skeleton"
            type="table">

        </v-skeleton-loader>
        <ASuccessFour :api="api"/>
        <v-row class="mt-3"
            v-if="!api.isLoading && data!=null">

            <v-col
             align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <!--BOC : Create a form here-->
                    <v-toolbar
                        class="secondary white--text pa-1">
                        <v-toolbar-title
                            class="text-h5">
                            Special Remark
                        </v-toolbar-title>
                    </v-toolbar>
                    <div
                        class="mt-5">
                        <v-row>
                            <v-col>
                                <div
                                    class="d-flex justify-start px-3 mt-3">
                                    <v-textarea
                                        outlined
                                        auto-grow
                                        label="Please write your remark..."
                                        width="auto"
                                        height="auto"
                                        v-model="data.remark">
                                    </v-textarea>
                                </div>
                                <div
                                    class="d-flex justify-end">
                                    <v-btn
                                        color="primary"
                                        plain
                                        @click="()=>{
                                            $router.go(-1);
                                        }">
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="validateInput">
                                        Submit
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!--EOC-->
                </v-card>
            </v-col>
          
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
    AConfirmation,
    ASuccessFour,
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:{
            remark:"",
            company_id:null,
            user_id:null,
        },
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.status==='fail'){
                this.api.isLoading = false;
                return 1;
            }
            if(resp.class==="getCompanyRemark"){
                this.data = resp.data;
            }
            if(resp.class==="store"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Update Company Remark";
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.data.company_id = this.$router.history.current.params.id;
            this.data.user_id = this.$store.getters.getUserId;
            let fetchCompanyRemarkApi = this.fetchCompanyRemark();
            this.$api.fetch(fetchCompanyRemarkApi);
        },
        fetchCompanyRemark(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/special_remark/company/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
            let createApi = this.create();
            this.$api.fetch(createApi);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/special_remark/company";
            let formData = new FormData;
            formData.append('company_id',this.data.company_id);
            formData.append('user_id',this.$store.getters.getUserId);
            formData.append('remark',this.data.remark);
            tempApi.params = formData;
            return tempApi;
        },  
        redirectBack(){
            this.$router.go(-1);

        }
    }
}
</script>